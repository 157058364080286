import React from 'react';

const UserGroupIcon = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.666 26.6668H29.3327V24.0002C29.3327 21.791 27.5418 20.0002 25.3327 20.0002C24.0585 20.0002 22.9235 20.5959 22.191 21.524M22.666 26.6668H9.33268M22.666 26.6668V24.0002C22.666 23.1252 22.4975 22.2896 22.191 21.524M9.33268 26.6668H2.66602V24.0002C2.66602 21.791 4.45688 20.0002 6.66602 20.0002C7.94016 20.0002 9.07516 20.5959 9.80768 21.524M9.33268 26.6668V24.0002C9.33268 23.1252 9.50125 22.2896 9.80768 21.524M9.80768 21.524C10.7907 19.0682 13.1924 17.3335 15.9993 17.3335C18.8063 17.3335 21.208 19.0682 22.191 21.524M19.9993 9.3335C19.9993 11.5426 18.2085 13.3335 15.9993 13.3335C13.7902 13.3335 11.9993 11.5426 11.9993 9.3335C11.9993 7.12436 13.7902 5.3335 15.9993 5.3335C18.2085 5.3335 19.9993 7.12436 19.9993 9.3335ZM27.9993 13.3335C27.9993 14.8063 26.8054 16.0002 25.3327 16.0002C23.8599 16.0002 22.666 14.8063 22.666 13.3335C22.666 11.8607 23.8599 10.6668 25.3327 10.6668C26.8054 10.6668 27.9993 11.8607 27.9993 13.3335ZM9.33268 13.3335C9.33268 14.8063 8.13878 16.0002 6.66602 16.0002C5.19326 16.0002 3.99935 14.8063 3.99935 13.3335C3.99935 11.8607 5.19326 10.6668 6.66602 10.6668C8.13878 10.6668 9.33268 11.8607 9.33268 13.3335Z"
        stroke="#191A25"
        strokeWidth="2.33"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserGroupIcon;
