import React, { useState } from 'react';

import BaseLayout from '../../components/templates/BaseLayout';
import SEO from '../../components/atoms/utility/seo';
import ActivityList from '../../components/organisms/ActivityList';
import TrendingIcon from '../../components/atoms/icons/TrendingIcon';
import ActivityFilter from '../../components/molecules/ActivityFilter';
import { TimeBasedFilterDropdownOptions } from '../../constants/activity';
import Button from '../../components/atoms/inputs/Button';
import useFetchPoi from '../../utils/hooks/useFetchPoi';
import LocationPin from '../../components/atoms/icons/LocationPin';
import CommunityCardList from '../../components/organisms/CommunityCardList';
import Tabs from '../../components/atoms/tabs';
import UserGroupIcon from '../../components/atoms/icons/UserGroupIcon';
import { stringConcatenator } from '../../utils/helpers/strings';

const tabList = [
  { id: 'activity', name: 'Activities', icon: TrendingIcon },
  { id: 'community', name: 'Communities', icon: UserGroupIcon },
];

const SEOForPage = () => (
  <SEO
    title="Discover Local Business With Flockx | Neighborhood Spotlights & Deals"
    keywords={[
      'Find Places to Go',
      'Discover Local Businesses',
      'Explore Local Venues',
      'Neighborhood Business Finder',
      'Find Local Business Deals',
      'Shop Local Businesses',
      'Local Business Network',
      'Community-Driven Business Events',
      'Local Business Spotlights',
    ]}
    description={
      'Find your next adventure with our comprehensive directory of local business events. From neighborhood venues to exclusive deals, flockx is your gateway to community-driven activities and a vibrant local business network. Shop and support local businesses while connecting with like minded individuals right in your backyard!'
    }
  />
);

export default function BusinessDetailPage({ params }) {
  const poiId = params['id'];
  const { poiDetailList, associatedCommunities, isLoading } = useFetchPoi({
    poiIds: [poiId],
  });

  const poiDetail = poiDetailList[0];

  const [activeTab, setActiveTab] = useState(tabList[0]);

  const [timeFilterValue, setTimeFilterValue] = useState(
    TimeBasedFilterDropdownOptions[1]
  );

  if (isLoading || !poiDetail) {
    return (
      <BaseLayout
        mainSectionClass="z-20 relative flex-grow h-full w-full pt-[40px] mb-10"
        hideHeroImage
        customHeaderClass=" "
      >
        <SEOForPage />
        <div className="animate-pulse skeleton group w-full bg-gray-200 h-[246px] md:h-[400px]" />
        <div className="animate-pulse skeleton group py-[18px] px-4 sm:px-6 flex-col justify-start items-start gap-4 inline-flex">
          <div className="w-[300px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
          <div className="w-[200px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
          <div className="w-[200px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
          <div className="w-[200px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
        </div>
      </BaseLayout>
    );
  }

  return (
    <>
      <BaseLayout
        mainSectionClass="z-20 relative flex-grow h-full w-full"
        hideHeroImage
        customHeaderClass=" "
      >
        <SEOForPage />

        {!!associatedCommunities?.length && (
          <div className="w-full h-[246px] md:h-[600px]">
            <img
              className="w-full h-full object-cover"
              src={associatedCommunities[0].banner_image_url}
            />
          </div>
        )}
        <HeroContent
          businessDetail={poiDetail}
          associatedCommunities={associatedCommunities || []}
          handleTabChange={setActiveTab}
        />
        <div className="mx-8 md:mx-64 my-4 md:my-12">
          <Tabs
            tabList={tabList}
            activeTab={activeTab}
            handleTabClick={setActiveTab}
          />
        </div>

        <div className="px-8 md:px-32 mb-4 md:mb-8">
          <ActivityFilter
            timeFilterTitle={timeFilterValue.title}
            timeFilterValue={timeFilterValue.value}
            handleSelectTimeFilter={setTimeFilterValue}
            hideCommunityFilter={true}
            isVisible={
              !!poiDetail?.total_activity_count &&
              activeTab.id === tabList[0].id
            }
          />
        </div>

        <div className="px-8 md:px-8">
          <ActivityList
            poiId={poiDetail.db_ref}
            timeFilterValue={timeFilterValue.value}
            isVisible={activeTab.id === tabList[0].id}
          />
        </div>

        <div className="px-8 md:px-32">
          <CommunityCardList
            communitiesToDisplay={associatedCommunities}
            isLoading={isLoading}
            isVisible={activeTab.id === tabList[1].id}
          />
        </div>
      </BaseLayout>
    </>
  );
}

function HeroContent({
  businessDetail,
  associatedCommunities = [],
  handleTabChange,
}) {
  const handleLocationClick = () => {
    const url = 'https://maps.google.com/?q=' + businessDetail.location;
    window.open(url, '_blank');
  };

  return (
    <div className="w-full gap-1.5 md:gap-4 flex flex-col md:mt-[56px] mt-[16px] px-6 items-center justify-center">
      <div className="text-slate-800 text-[22px] lg:text-6xl font-bold text-center">
        {businessDetail?.name}
      </div>
      <div
        className="cursor-pointer px-3 py-1 bg-zinc-100 rounded-[60px] justify-center items-center gap-1.5 inline-flex"
        onClick={handleLocationClick}
      >
        <LocationPin className="w-4 h-4 md:w-6 md:h-6" />
        <div className="justify-start items-center gap-1 flex">
          <div className="text-gray-900 text-xs md:text-base font-medium font-['DM Sans'] leading-tight">
            {stringConcatenator(
              [
                businessDetail.house_number,
                businessDetail.street,
                businessDetail.city,
              ],
              ', '
            )}
          </div>
        </div>
      </div>
      <div
        className="cursor-pointer justify-start items-start -gap-3 inline-flex"
        onClick={() => handleTabChange(tabList[1])}
      >
        {associatedCommunities?.map((community) => (
          <div className="justify-center items-center flex -ml-[7px] -md:ml-3">
            <img
              src={community.image_url}
              alt={community.name}
              title={community.name}
              className="w-[35px] h-[35px] md:w-12 md:h-12 object-cover object-center"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
